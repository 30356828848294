export async function chunkFile(file, config) {
    return new Promise((resolve, reject) => {
        try {
            let chunkSize = (config && config.chunkSize ? config.chunkSize : (10 * 1024 * 1024));   // 10MB Chunk Size 
            let fileSize = file.size;
            let numChunks = Math.ceil(fileSize/chunkSize,chunkSize);
            let chunk = 0;
            let chunks = [];

            console.log('file size..',fileSize);
            console.log('chunks...',chunks);

            // Chunk File
            while (chunk < numChunks) {
                let offset = chunk*chunkSize;
                console.log('current chunk..', chunk);
                console.log('offset...', offset);
                console.log('file blob from offset...', offset);
                // let currentChunk = (file.buffer.slice(offset,(chunkSize + offset)));
                let currentChunk = (file.slice(offset,(chunkSize + offset)));
                console.log(currentChunk);
                chunks.push(currentChunk);
                chunk++;
            }

            console.log(`Chunks :: `, chunks);
            return resolve({
                message: 'Chunked Successfully!',
                ok: true,
                errors: [],
                file: file,
                chunks: chunks
            });
        } catch (error) {
            return reject({
                message: 'Failed to Chunk File.',
                ok: false,
                errors: error,
                file: file,
                chunks: []
            });
        }
    });
}