import React from 'react'
import '../form.scss'

export const select = (field) => (
  <div
    className={field.containerClass ? field.containerClass : 'field-group'}
    style={field.groupStyle ? field.groupStyle : {}}
  >
    <label className={field.labelClass}>{field.label}</label>
    <div className='form-input-container'>
      {field.customSelect ? (
        <div className={field.customSelect ? field.customSelect : 'select'}>
          <select
            disabled={field.disabled}
            className={field.inputClass}
            {...field.input}
            defaultValue={field.defaultValue || ''}
          >
            {field.children}
          </select>
        </div>
      ) : (
        <select
          className={field.inputClass}
          {...field.input}
          defaultValue={field.defaultValue || ''}
        >
          {field.children}
        </select>
      )}

      <span className='form-error'>
        {field.meta.touched && field.meta.error ? field.meta.error : ''}
      </span>
    </div>
  </div>
)
