//import React, {useContext} from "react";
import React from "react";
//import { createCaller } from "react-outside-call";

export const CMSContext = React.createContext({
    id: null, // The ID of this section in the backend database
    name: "", // The name of this section
    level: 0, // The level of the component in the cms tree
    parent: null, // The parent section wrapping this section (null if this is the first level of the cmd tree)
    namePath: "", // Aggregation of the names of sections leading to this current section. Start with the base
    idPath: "", // Aggregation of the section IDs leading to this current section
    data: [], // The data elements for this section
});

/*
export const callConfig = createCaller({
    myCMSContext: () => useContext(CMSContext),
});
*/

