import React from 'react';
import '../form.scss';

export const checkbox = (field) => (
    <div className="field-group checkbox" style={field.groupStyle ? field.groupStyle : {}}>
        <div className="form-input-container">
          <input {...field.input} type="checkbox" defaultValue={field.defaultValue || false} />
          <label className={field.labelClass} >{field.label}</label>
        </div>
        <span className="form-error">{field.meta.touched && field.meta.error ? field.meta.error : ''}</span>
    </div>
);