import React, { Component } from 'react'
import { connect } from 'react-redux'
import Swal from "sweetalert2";
import { Pagination } from 'dg-pagination'

// Components
import { SortableTableBody } from './SortableTable'
import ListOptions from './listOptions'

// Styles
import '../../styles.scss'

// Actions
import { deleteCMS, fetchCMSData, updateCMSSection } from '../../actions/cms.action'

function compare(a, b) {
  if (a.last_nom < b.last_nom) {
    return -1
  }
  if (a.last_nom > b.last_nom) {
    return 1
  }
  return 0
}

class CMSList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      page_size: 10,
      total_pages: 0,
      total_items: 0,
      isLoading: false
    }

    this.onDelete = this.onDelete.bind(this)
    this.paginate = this.paginate.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
  }

  componentDidMount() {
    this.paginate(this.state.page, this.state.page_size)
  }

  paginate(page, page_size) {
    this.props.fetchCMSData(this.props.criteria || { level: 1 })
  }

  onDelete(event, value) {
    event.preventDefault()
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.props
          .deleteCMS(value._id)
          .then((res) => {
            Swal.fire('Deleted!', ' Section has been deleted.', 'success')
            this.paginate(this.state.page, this.state.page_size)
          })
          .catch((res) => {
            Swal.fire('Error', res.message, 'error')
          })
      } else {
        console.log('redirect')
      }
    })
  }

  onSortEnd(sortData) {
    // Uppate the first index
    const form_1 = new FormData()
    form_1.append(
      'data',
      JSON.stringify({
        ...this.props.cms.sections[sortData.oldIndex],
        order: sortData.newIndex + 1
      })
    )
    this.props.updateCMSSection(this.props.cms.sections[sortData.oldIndex]._id, form_1)

    // Uppate the second index
    const form_2 = new FormData()
    form_2.append(
      'data',
      JSON.stringify({
        ...this.props.cms.sections[sortData.newIndex],
        order: sortData.oldIndex + 1
      })
    )
    this.props.updateCMSSection(this.props.cms.sections[sortData.newIndex]._id, form_2)

    console.log('oldIndex', sortData)
  }

  render() {
    let items = []
    if (this.props.cms && this.props.cms.sections && this.props.cms.sections.length > 0) {
      // Sort the data first by order
      this.props.cms.sections.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))

      items = this.props.cms.sections
    } else {
      items = []
      {
        /*rows = [
                <tr>
                    <td colSpan="5">
                        {this.props.nested
                            ? "No nested sections exist"
                            : "No CMS sections exist"}
                    </td>
                </tr>,
            ];*/
      }
    }
    const TableRow = ({ value, index }) => {
      return (
        // Create the row objects
        <React.Fragment>
          <td>{value.name}</td>
          <td>{value.level}</td>
          <td>
            {new Date(value.createdAt).toLocaleDateString()}{' '}
            {new Date(value.createdAt).toLocaleTimeString()}
          </td>
          <td>
              <ListOptions
                  onDelete={(e) => this.onDelete(e, value)}
              onEdit={this.props.onEdit ? () => this.props.onEdit(value) : undefined}
            />
          </td>
        </React.Fragment>
      )
    }

    return (
      <div className='admin-container'>
        <main>
          <div className='List-Container'>
            <div className='header-container'>
              <div className='list-header'>
                <h1>{this.props.nested ? 'Nested CMS Sections' : 'CMS Management'}</h1>
              </div>
            </div>
            <div className='list'>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Level</th>
                    <th>Created On</th>
                    <th>Actions</th>
                    <th>Reorder</th>
                  </tr>
                </thead>
                <SortableTableBody
                  onSortEnd={this.onSortEnd}
                  items={items}
                  TableRow={TableRow}
                  includeHandler
                />
              </table>
              <Pagination
                type='Standard'
                paginate={this.paginate}
                page_size={this.state.page_size}
                page={this.state.page}
                total_pages={this.state.total_pages}
                total_items={this.state.total_items}
              />
            </div>
          </div>
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  cms: state.cms
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCMSData: (criteria) => dispatch(fetchCMSData(criteria)),
    updateCMSSection: (id, data) => dispatch(updateCMSSection(id, data)),
    deleteCMS: (id) => dispatch(deleteCMS(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CMSList)
