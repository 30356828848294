import React from 'react'
import '../form.scss'

export const basicInput = ({
  input,
  label,
  labelClass,
  placeholder,
  containerClass,
  preventPastDate,
  preventFutureDate,
  inputClass,
  type,
  disabled,
  autoComplete,
  meta: { asyncValidating, touched, error, warning }
}) => (
  <div className={containerClass}>
    {label ? (
      <label className={labelClass ? labelClass : ''}>{label}</label>
    ) : (
      ''
    )}
    <input
      {...input}
      placeholder={placeholder}
      className={inputClass}
      min={preventPastDate ? new Date().toISOString().split('T')[0] : ''}
          max={preventFutureDate ? new Date().toISOString().split('T')[0] : ''}
      type={type}
      disabled={disabled}
      autoComplete={autoComplete}
    />
    <span className='form-error'>
      {(touched && error) || asyncValidating ? error : ''}
    </span>
  </div>
)
