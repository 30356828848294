import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import '../form.scss';

export const captcha = (props) => (
    <div className="field-group captcha" style={props.groupStyle ? props.groupStyle : {}}>
        <div className="form-input-container">
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                onChange={props.input.onChange}
            />
        </div>
        <span className="form-error">{props.meta.touched && props.meta.error ? props.meta.error : ''}</span>
    </div>
);