import React from 'react';
import '../form.scss';
import Switch from "react-switch";

export const toggle = (field) => (
  <div className={field.containerClass ? field.containerClass : 'field-group toggle'}>
        <label className={field.labelClass}>
          <span>{field.label}</span>
        </label>
        <div
            className={
                field.inputContainerClass ? 
                field.inputContainerClass : 'form-input-container'
            }
        >
            <Switch
                {...field.input}
                checked={field.input.value}
                onChange={field.input.onChange}
                icons={field.icons || false}
                className={field.inputClass}
                disabled={field.disabled}
            />
            
        </div>
        <div className="form-input-container error">
            <span className='form-error'>
                {(field.meta.touched && field.meta.error) || field.meta.asyncValidating ? field.meta.error : ''}
            </span>
        </div>
  </div>
)
