// CMS
export const FETCH_CMS_SECTION = "FETCH_CMS_SECTION";
export const UPDATE_CMS_SECTION = "UPDATE_CMS_SECTION";
export const CREATE_CMS_ELEMENT = "CREATE_CMS_ELEMENT";
export const GET_ALL_CMS = "GET_ALL_CMS";
export const DELETE_CMS = "DELETE_CMS";
export const UPDATE_CMS = "UPDATE_CMS";
export const FETCH_CMS_SECTION_BY_ID = "FETCH_CMS_SECTION_BY_ID";
export const CREATING_CMS_ELEMENT = "CREATING_CMS_ELEMENT";
export const CLEAR_CMS_SECTIONS = "CLEAR_CMS_SECTIONS";
export const UPDATE_CURRENT_CMS_SECTION = "UPDATE_CURRENT_CMS_SECTION";
