import React, { Component } from 'react';

// Style
import './listOptions.scss';

// Actions

class DgListOptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDashboardsOpen: false
        };
    }

    render() {
        let options = this.props.options.map((value, index) => (
            <button
                key={`list_option_${value.name}_${index}`}
                type="button"
                className="dropdown-link"
                onClick={value.onClick}
            >
                {value.icon}
                <span className="link-text">{value.name}</span>
            </button>
        ));

        return (
            <nav className="DgListOptions">
                <ul className="ListOptions-nav">

                    <li className={`nav-item ${this.state.isDashboardsOpen ? `show`: ''}`}>
                        <div className="nav-link dropdown" onClick={() => this.setState({isDashboardsOpen: !this.state.isDashboardsOpen})}>
                            {
                                this.props.icon ? this.props.icon : (
                                    <svg 
                                        aria-hidden="true" 
                                        focusable="false" 
                                        data-prefix="fas" 
                                        data-icon="ellipsis-v" 
                                        className="svg-inline--fa fa-ellipsis-v fa-w-6" 
                                        role="img" 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        viewBox="0 0 192 512"
                                    >
                                        <path 
                                            fill="currentColor" 
                                            d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"
                                            className="fa-primary"
                                        >
                                        </path>
                                    </svg>
                                )
                            }
                            <div className={`dropdown-menu ${this.state.isDashboardsOpen ? `show`: ''}`}>
                                {options}
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default DgListOptions;