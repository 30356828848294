import React, { Component } from 'react'
import { connect, ReactReduxContext } from "react-redux";
import { DgLazyComponent } from 'dg-utils'

import { CMSContext } from './cms-context'

import { fetchSection, sectionUpdate, cmsStateAccess } from '../actions/cms.action'

function containsObject(list, keyName, value) {
  let i
  for (i = 0; i < list.length; i++) {
    if (list[i][keyName] === value) {
      return list[i]
    }
  }
  return false
}

class CMSSectionComponent extends Component {
  // Extract the CMS context to be used in this component
  static contextType = CMSContext

  constructor(props) {
    super(props)
    this.state = { loading: true }
    console.log(`Constructing: ${props.name}`)
  }

  componentDidMount() {
    // Define the name, level and parent for this section based off its parent
    this.sectionData = {} // Initialise the empty object which will hold the cms data for this section
    this.sectionData.level = this.context.level + 1 // Increase the level of this component by one compared to its parent
    this.sectionData.parent = this.context.level > 0 ? this.context.id : null // A parent will only exist if the parent section was deeper than level 0
    this.sectionData.name = this.props.name // The name which has been provided for this section
    // Define the name path for this section
    if (this.sectionData.level > 1) {
      this.sectionData.namePath = this.context.namePath + '.' + this.props.name
    } else {
      this.sectionData.namePath = this.props.name
    }

    if (containsObject(this.props.cmsSections, 'namePath', this.sectionData.namePath)) {
      console.log(`Found ${this.sectionData.namePath}`)
      this.sectionData = containsObject(
        this.props.cmsSections,
        'namePath',
        this.sectionData.namePath
      )
      // Set the state to no longer loading
      this.setState({ loading: false })
    } else {
      // Obtain this section from the database
      // This call will create the section in the database if it does not already exist
      this.props
        .fetchSection({
          name: this.sectionData.name,
          parent: this.sectionData.parent,
          level: this.sectionData.level,
          namePath: this.sectionData.namePath
        })
        .then((cmsResponse) => {
          console.log(`Sucessfully obtained section: ${this.sectionData.name}`)
            let cmsData = cmsResponse.data;

            // Update the section id in the sectionData object
          this.sectionData.id = cmsData.data._id
          this.sectionData._id = cmsData.data._id
          // Extract the CMS data from the returned object
          this.sectionData.data = cmsData.data.data
          this.sectionData.order = cmsData.data.order
          // Update the CMS data in the store
          this.props.sectionUpdate(this.sectionData)
          // Set the state to no longer loading
          this.setState({ loading: false })
        })
        .catch((err) => {
          console.log(`Error obtaining section: ${this.sectionData.name}`)
          console.log(err)
          throw err
        })
    }
  }

  render() {
    const { children, cmsElmt } = this.props
    return this.state.loading ? (
      <div style={{ height: '100vh', width: "100%" }}>.</div>
    ) : (
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <CMSContext.Provider value={this.sectionData}>
            <CMSContext.Consumer>
              {(context) =>
                children({
                  // Pass the props which should be available to child components
                  context, // The section's context
                  cmsElmt: (
                    name,
                    type,
                    defaultValue // The function which can be called by any child component to access/create a cms variable
                  ) => cmsElmt(name, type, defaultValue, context, store)
                })
              }
            </CMSContext.Consumer>
          </CMSContext.Provider>
        )}
      </ReactReduxContext.Consumer>
    )
  }
}

class CMSSection extends React.Component {
    render() {
        return (
            <DgLazyComponent Component={CMSSectionComponent} {...this.props} />
        );
    }
}

const mapStateToProps = (state) => ({
    cmsElmt: (name, type, defaultValue, sectionContext, store) =>
        cmsStateAccess(name, type, defaultValue, sectionContext, state, store),
    cmsSections: state.cms.sections,
});

const mapDispatchToProps = (dispatch) => ({
    fetchSection: (name, parent, level) =>
        dispatch(fetchSection(name, parent, level)),
    sectionUpdate: (data) => dispatch(sectionUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CMSSection);
