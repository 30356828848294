import React from 'react';
import '../form.scss';

export const loading = ({
  input,
  label,
  containerClass,
  inputContainerClass,
  labelClass,
  value,
  max,
  meta: { asyncValidating, touched, error, warning }
}) => (
  <div className={containerClass ? containerClass : 'field-group'}>
    <label className={labelClass}>{label}</label>
    <div className={inputContainerClass ? inputContainerClass : 'form-input-container'}>
        <progress 
            {...input}
            value={value}
            max={max}
        >
            {`${value}%`}
        </progress>
        <span className='form-error'>
            {(touched && error) || asyncValidating ? error : ''}
        </span>
    </div>
  </div>
)
