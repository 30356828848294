import React from 'react';
import '../../form.scss';

export const radio = ({
    input, 
    label, 
    labelClass,
    groupStyle,
    inputValue,
    meta: { touched, error }
  }) => (
    <div className="field-group checkbox" style={groupStyle ? groupStyle : {}}>
      <div className="form-input-container">
        <input {...input} type="radio" value={inputValue} />
        <label className={labelClass} >{label}</label>
      </div>
      <span className="form-error">{touched && error ? error : ''}</span>
    </div>
);