import {
    CREATING_CMS_ELEMENT,
    CLEAR_CMS_SECTIONS,
    FETCH_CMS_SECTION_BY_ID,
    FETCH_CMS_SECTION,
    UPDATE_CMS_SECTION,
    CREATE_CMS_ELEMENT,
    UPDATE_CURRENT_CMS_SECTION
} from "./actions/types";

const initialState = {
    sections: [],
    currentSection: {},
    creatingElements: [],
};

const setPath = (object, path, value) =>
    path
        .split(".")
        .reduce(
            (o, p, i) =>
                (o[p] = path.split(".").length === ++i ? value : o[p] || {}),
            object
        );

const containsObject = (list, keyName, value) => {
    let i;
    for (i = 0; i < list.length; i++) {
        if (list[i][keyName] === value) {
            return list[i];
        }
    }
    return false;
};

const objectIndex = (list, keyName, value) => {
    let i;
    for (i = 0; i < list.length; i++) {
        if (list[i][keyName] === value) {
            return i;
        }
    }
    return null;
};

export default function (state = initialState, action) {
    let sections;

    switch (action.type) {
        case UPDATE_CMS_SECTION:
            sections = state.sections;
            let sectionIndex = objectIndex(sections, "_id", action.payload._id);
            if (sectionIndex != null) {
                sections[sectionIndex] = action.payload;
            } else {
                sections.push(action.payload);
            }
            //setPath(sections, action.payload.namePath, action.payload);
            return {
                ...state,
                sections,
            };
        case UPDATE_CURRENT_CMS_SECTION:
            return {
                ...state,
                currentSection: action.payload,
            };
        case FETCH_CMS_SECTION_BY_ID:
            return {
                ...state,
                currentSection: action.payload,
            };
        case CLEAR_CMS_SECTIONS:
            return {
                ...state,
                sections: action.payload,
                currentSection: {},
            };
        case FETCH_CMS_SECTION:
            return {
                ...state,
                sections: action.payload,
            };
        case CREATE_CMS_ELEMENT:
            // TODO update the state on first reload
            return {
                ...state,
            };
        case CREATING_CMS_ELEMENT:
            // Creates a list of elements which have already been sent to the backend for creation
            // The purpose of this list is to prevent duplicate requests which clog up the server
            return {
                ...state,
                creatingElements: action.payload,
            };
        default:
            return state;
    }
}
