import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

// Actions

// Components

class HelmetHeader extends Component {
  constructor(props) {
    super(props)

    this.find = this.find.bind(this)
  }

  find(property, url) {
    if (this.props.seojson) {
      let val = this.props.seojson[property].find((prop) => prop.url === url)

      return val && val.content ? val.content : ''
    } else {
      return ''
    }
  }

  render() {
    const currentPath = window.location.pathname

    return (
      <Helmet>
        <title> {this.find('meta_titles', currentPath)} </title>
        <meta
          name='description'
          content={this.find('meta_descriptions', currentPath)}
        />

        {this.props.children}
      </Helmet>
    )
  }
}

export default HelmetHeader
